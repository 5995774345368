import { useState, useEffect } from "react";
import { Outlet, useLocation, Link } from "react-router-dom";

import axios from "axios";

import Header from "../components/Header";
import Footer from "../components/Footer";
// import SubscriptionModal from '../components/Modals/SubscriptionModal';

import Logo from "../styles/icons/Logo";
import sneaker from "../images/sneaker-hero-image.jpg";

import "../css/style.css";

const MainLayout = ({ savedItems, pagesContent, products, filters }) => {
  let location = useLocation();
  // const [isShown, setIsShown] = useState(false);

  /*temporary code */
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get("/user/isAdmin", {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => setIsAdmin(true))
      .catch((error) => {
        console.log(error.response);
      });
  });
  /*end of temporary code */

  const hash = window.location.hash;

  useEffect(() => {
    if (!hash) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location, hash]);

  // const modal = localStorage.getItem('subscriptionModal');

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (modal !== 'opened') {
  //       setIsShown(true);
  //       localStorage.setItem('subscriptionModal', 'opened');
  //     }
  //   }, 45000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <main>
      <Header
        savedItems={savedItems}
        pagesContent={pagesContent}
        products={products}
        filters={filters}
      />
      <Outlet />
      <Footer />
      {/* <SubscriptionModal isShown={isShown} setIsShown={setIsShown} /> */}
      {/* {isAdmin ? (
        <>
          <Header savedItems={savedItems} pagesContent={pagesContent} products={products} filters={filters} />
          <Outlet />
          <Footer />
        </>
      ) : (
        <>
          <>
            <section
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${sneaker})`,
                objectFit: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <img
                src={sneaker}
                style={{ objectFit: 'cover', objectPosition: 'center center', height: '100vh' }}
                alt="temp"
              />
              <div style={{ position: 'absolute', top: '40px', left: '50px', zIndex: '2' }}>
                <Logo logoColor={'white'} />
              </div>

              <div
                style={{
                  position: 'absolute',
                  top: '200px',
                  zIndex: '2',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <Link to="/temporary">
                  <span style={{ color: 'white', fontSize: '40px' }}>Website is under construction</span>
                </Link>
              </div>
            </section>
          </>
        </>
      )} */}
    </main>
  );
};

export default MainLayout;
