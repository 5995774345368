import { useState, useEffect, useCallback } from "react";

import Style from "./Style";
import MediaContent from "../../components/MediaContent";

import { Splide, SplideSlide } from "@splidejs/react-splide";

import { addClass } from "../../utils/utils";
import { removeClass } from "../../utils/utils";

import "@splidejs/react-splide/css";

import "../../css/style.css";
import "../../css/sneaker_news.css";
import "../../css/sneaker_blogpost.css";
import "../../css/sneaker_styles.css";

import "./styles.css";

const Styles = ({ styles, filters, pagesContent }) => {
  const [filteredStyleItems, setFilteredStyleItems] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState("all");

  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    const value = selectedStyle;
    let result = [];

    if (value === "all") {
      result = styles;
    } else {
      result = styles.filter((item) => item.style === value);
    }

    setFilteredStyleItems(result);
  }, [selectedStyle, styles]);

  const addClassOnScroll = useCallback(
    (e) => {
      const window = e.currentTarget;

      if (y > window.scrollY) {
        addClass("scrollable-categories", "slick-filter-scroll");
      } else if (y < window.scrollY) {
        removeClass("scrollable-categories", "slick-filter-scroll");
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", addClassOnScroll);

    return () => {
      window.removeEventListener("scroll", addClassOnScroll);
    };
  }, [addClassOnScroll]);

  const changeStyles = (filter, e) => {
    e.stopPropagation();
    setSelectedStyle(filter);
  };

  return (
    <>
      <MediaContent pagesContent={pagesContent} />
      <>
        <div id="abc" style={{ position: "fixed" }}></div>
        <section id="styles">
          <div className="scrollable-categories container-blog-img gallery-filter">
            {filters ? (
              <Splide
                options={{
                  type: "loop",
                  height: 100,
                  perPage: 4,
                  perMove: 4,
                  gap: "2rem",
                  pagination: false,
                  drag: false,
                  breakpoints: {
                    640: {
                      type: "loop",
                      height: 70,
                      perPage: 2,
                      perMove: 1,
                      drag: true,
                      dragMinThreshold: 4,
                      pagination: true,
                      classes: {
                        page: "splide__pagination__page pag-button",
                      },
                    },
                  },
                }}
                aria-label="2feat-styles"
              >
                <SplideSlide key={"all"}>
                  <div
                    className="category"
                    onClick={(e) => changeStyles("all", e)}
                  >
                    <div
                      className={
                        selectedStyle === "all"
                          ? "filter-item active"
                          : "filter-item"
                      }
                      data-filter=""
                    >
                      All
                    </div>
                  </div>
                </SplideSlide>
                {filters.styles &&
                  filters.styles.map((st) => {
                    return (
                      <SplideSlide key={st}>
                        <div
                          className="category"
                          onClick={(e) => changeStyles(st, e)}
                        >
                          <div
                            className={
                              selectedStyle === st
                                ? "filter-item active"
                                : "filter-item"
                            }
                            data-filter=""
                          >
                            {st}
                          </div>
                        </div>
                      </SplideSlide>
                    );
                  })}
              </Splide>
            ) : null}
          </div>
          <div className="styles-container">
            <div className="styles-wrapper styles-container">
              {filteredStyleItems?.length > 0
                ? filteredStyleItems.map((style, index) => (
                    <Style key={index + "style"} style={style} />
                  ))
                : "No styles in this category"}
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Styles;
