import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Burger from "../../pages/HomePage/Burger";
import SearchModal from "../Modals/SearchModal";

import useMobile from "../../hooks/useMobile";

import { addClass } from "../../utils/utils";
import { removeClass } from "../../utils/utils";

import { pagesWithMedia } from "../../constants/data";

import Logo from "../../styles/icons/Logo";
import SavedItems from "../../styles/icons/SavedItems";

import "./styles.css";

const Header = ({ savedItems, pagesContent, products, filters }) => {
  const isMobile = useMobile();

  let location = useLocation();

  const [tabPositionY, setTabPositionY] = useState(0);
  const [logoColor, setLogoColor] = useState("#ffffff");
  const [headerVariant, setHeaderVariant] = useState("transparent");

  const savedItemsLenght = [...savedItems].length;

  const setHeaderTransparent = () => {
    removeClass("header", "header-scroll");
    setLogoColor("#ffffff");
    setHeaderVariant("transparent");
  };

  const setHeaderWhite = () => {
    addClass("header", "header-scroll");
    setLogoColor("#cccccc");
    setHeaderVariant("white");
  };

  setTimeout(() => {
    setTabPositionY(
      document.getElementById("scroll-position")?.getBoundingClientRect()?.top +
        window.scrollY
    );
  }, "500");

  useEffect(() => {
    window.history.scrollRestoration = "manual";

    const filteredContent = pagesContent?.filter((page) => {
      if (location.pathname === "/") {
        return page.page === "main";
      } else {
        return page.page === location.pathname.substring(1);
      }
    });

    const currentPage = pagesWithMedia.find((page) => {
      if (page.includes("main") && location.pathname === "/") return "main";

      if (page.includes(location.pathname.substring(1))) return page;

      return undefined;
    });

    if (!filteredContent) return;

    if (currentPage && currentPage === filteredContent[0]?.page) {
      setHeaderTransparent();
    } else {
      setHeaderWhite();
    }
  }, [location, pagesContent]);

  const addClassOnScroll = () => {
    const scrollY = window.scrollY;

    if (!document.getElementById("scroll-position")) {
      return;
    }

    if (scrollY > tabPositionY - 80) {
      setHeaderWhite();
    } else {
      setHeaderTransparent();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", addClassOnScroll);

    return () => window.removeEventListener("scroll", addClassOnScroll);
  });

  return (
    <header className="header">
      <div className="main-header">
        <SearchModal products={products} filters={filters} />
        {!isMobile ? (
          <>
            <Link to="/apparel" className="menu-items">
              APPAREL
            </Link>
            <Link to="/styles" className="menu-items">
              STYLES
            </Link>
          </>
        ) : null}
        <div className="logo-container logo">
          <Link to="/">
            <Logo logoColor={logoColor} />
          </Link>
        </div>
        {!isMobile ? (
          <>
            <Link to="/news" className="menu-items">
              NEWS
            </Link>
            <Link to="/contact_us" className="menu-items">
              CONTACT US
            </Link>
            {savedItemsLenght > 0 ? (
              <Link className="saved-items-button" to="/saved_item">
                <div className="saved-items-quantity">
                  <span>{savedItemsLenght}</span>
                </div>
                <SavedItems />
              </Link>
            ) : null}
          </>
        ) : null}
        {isMobile ? (
          <Burger
            savedItemsLenght={savedItemsLenght}
            headerVariant={headerVariant}
          />
        ) : null}
      </div>
    </header>
  );
};

export default Header;
