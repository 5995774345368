import { useState, useEffect } from "react";

import MediaContent from "../components/MediaContent";
import Products from "./HomePage/HomeProducts/Products";
import Tabs from "./HomePage/Tabs";
import Filters from "../components/Filters";

const Apparel = ({
  products,
  savedItems,
  setSavedItems,
  refs,
  selectedTab,
  setSelectedTab,
  savedSlides,
  savedSlidesSetter,
  pagesContent,
}) => {
  const [apparel, setApparel] = useState(products);
  const [unionData, setUnionData] = useState(products);

  useEffect(() => {
    const apparelProducts = products?.filter(
      (item) => item.category !== "Sneaker"
    );

    setApparel(apparelProducts);
    setUnionData(apparelProducts);
  }, [products]);

  return (
    <>
      <MediaContent pagesContent={pagesContent} />
      <Tabs
        savedItems={savedItems}
        setSavedItems={setSavedItems}
        refs={refs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        savedSlides={savedSlides}
        products={products}
        savedSlidesSetter={savedSlidesSetter}
      />
      <Filters
        products={apparel}
        unionData={unionData}
        setUnionData={setUnionData}
        apparel
      />
      {unionData?.length > 0 ? (
        <section id="new-releases">
          <div id="apparel" className="container new-releases-container">
            <h1 className="uppercase">Apparel</h1>
            <Products
              products={unionData}
              savedItems={savedItems}
              refs={refs}
              setSavedItems={setSavedItems}
              savedSlidesSetter={savedSlidesSetter}
            />
          </div>
        </section>
      ) : (
        <div>Sorry, there is nothing to show</div>
      )}
    </>
  );
};

export default Apparel;
